// Generated by Framer (d275c2b)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Mu3I_ue2K"];

const serializationHash = "framer-Dv3ox"

const variantClassNames = {Mu3I_ue2K: "framer-v-1aev0y5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Mu3I_ue2K", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1aev0y5", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Mu3I_ue2K"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><Image background={{alt: "", fit: "fill"}} className={"framer-ryc4kq"} layoutDependency={layoutDependency} layoutId={"HNqEkEjFB"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Dv3ox.framer-1kl004r, .framer-Dv3ox .framer-1kl004r { display: block; }", ".framer-Dv3ox.framer-1aev0y5 { height: 304px; position: relative; width: 312px; }", ".framer-Dv3ox .framer-ryc4kq { flex: none; height: 128px; left: calc(50.00000000000002% - 264px / 2); overflow: visible; position: absolute; top: 24px; width: 264px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 304
 * @framerIntrinsicWidth 312
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameroTyAWHkT_: React.ComponentType<Props> = withCSS(Component, css, "framer-Dv3ox") as typeof Component;
export default FrameroTyAWHkT_;

FrameroTyAWHkT_.displayName = "Project";

FrameroTyAWHkT_.defaultProps = {height: 304, width: 312};

addFonts(FrameroTyAWHkT_, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})